import React, { useState, useEffect } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import IosMenu from "@meronex/icons/ios/IosMenu";
import MdClose from "@meronex/icons/md/MdClose";
import {
  TwitterIcon,
  DiscordIcon,
  OtherIcon,
  InstagramIcon,
  YoutubeIcon,
} from "../Shared/SvgIcons";

import {
  Container,
  IconContent,
  SidebarContent,
  MenuClose,
  MenuLinksWrapper,
  MenuItem,
  SocailWrapper,
  SocialLink,
} from "./styles";

export const SidebarMenu = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("");

  const menuItems = [
    { id: 1, name: "MINT", value: "mint" },
    { id: 2, name: "ROADMAP", value: "roadmap" },
    { id: 3, name: "LIGHTPAPER", value: "lightpaper" },
    //{ id: 4, name: "WHITELIST", value: "whitelist" },
    { id: 5, name: "TEAM", value: "team" },
    { id: 6, name: "FAQ", value: "faq" },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { width } = useWindowSize();

  const actionSidebar = (value) => {
    setIsMenuOpen(value);
    document.getElementById("sidebar_menu").style.width = value
      ? width > 489
        ? "340px"
        : "100vw"
      : "0";
  };

  useEffect(() => {
    if (isMenuOpen) {
      if (width <= 489) {
        document.getElementById("sidebar_menu").style.width = "100vh";
      } else {
        document.getElementById("sidebar_menu").style.width = "340px";
      }
    }
  }, [width]);

  const handleMenuClick = (index) => {
    let topPos = 0;
    if (index !== "whitelist" && index !== "lightpaper" && index !== "mint") {
      topPos = document.getElementById(index).offsetTop;
      window.scroll({
        top: topPos,
        left: 0,
        behavior: "smooth",
      });
    } else if (index === "whitelist") {
      window.open("http://wl.meta3oys.com", "_blank");
    } else if (index === "lightpaper") {
      window.open("https://meta3oys.com/Meta3oys_Lightpaper.pdf", "_blank");
    } else {
      window.open("https://mint.meta3oys.com", "_blank");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      menuItems.forEach((menu) => {
        if (menu.value !== "whitelist" && menu.value !== "lightpaper" && menu.value !== "mint") {
          const windowTop = window.scrollY;
          let topPos = 0;
          topPos = document.getElementById(menu.value).offsetTop;

          if (Math.abs(windowTop - topPos) < 100) {
            setSelectedMenu(menu.value);
          }
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Container>
      <IconContent onClick={() => actionSidebar(true)}>
        <IosMenu />
      </IconContent>
      <SidebarContent id="sidebar_menu">
        <MenuClose aria-label="close" onClick={() => actionSidebar(false)}>
          <MdClose />
        </MenuClose>
        <MenuLinksWrapper>
          {menuItems.map((menu) => (
            <MenuItem
              key={menu.id}
              active={selectedMenu === menu.value}
              onClick={() => handleMenuClick(menu.value)}
            >
              {menu.name}
            </MenuItem>
          ))}
          <SocailWrapper>
            <SocialLink href="https://instagram.com/meta3oys" target="_blank">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/meta3oys" target="_blank">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCOg7lA6ADoPuxlzRFdtdWBA" target="_blank">
              <YoutubeIcon />
            </SocialLink>
            <SocialLink href="https://discord.gg/meta3oys" target="_blank">
              <DiscordIcon />
            </SocialLink>
            <SocialLink
              href="https://opensea.io/collection/meta3oys"
              target="_blank"
            >
              <OtherIcon />
            </SocialLink>
          </SocailWrapper>
        </MenuLinksWrapper>
      </SidebarContent>
    </Container>
  );
};
