import React from "react";
import { useTheme } from "styled-components";
import { Button } from "../Shared";
import { MintContainer, MintContent, ContentBox } from "./styles";

export const Mint = () => {
  const theme = useTheme();

  /*var countDownDate = new Date("Feb 16, 2022 21:00:00 UTC+1").getTime();
  var x = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    document.getElementById("hours").innerHTML = hours;
    document.getElementById("min").innerHTML = minutes;
    document.getElementById("sec").innerHTML = seconds;

    if (distance < 0) {
      clearInterval(x);
      document.getElementById("demo").innerHTML = "EXPIRED";
    }
  }, 1000);*/

  return (
    <MintContainer bgimage={theme.images.mintHero} id="mint">
      <MintContent>
        <ContentBox>
          <h1>
            The Meta3oys private club
          </h1>
          {/* <Button disabled>mint</Button> */}
          <div className="mint-box">
            <div className='countdown countdown-text'>
              <span>MINT IS LIVE !</span>
            </div>
            <a href="https://mint.meta3oys.com" target="_blank" rel="noreferrer" style={{ color: "#fff", textDecoration: "none" }}>
             <Button className="mint-btn">MINT</Button>
            </a>
          </div>
        </ContentBox>
      </MintContent>
    </MintContainer>
  );
};
