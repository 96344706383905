import React from 'react'
import { useTheme } from 'styled-components'
import { DiscordIcon } from '../Shared/SvgIcons'

import {
  Container,
  DiscordWrapper,
  MinersWrapper,
  DiscordBlock
} from './styles'

export const ShipMint = () => {
  const theme = useTheme()

  const goToLink = (location) => {
    window.open(location, "_blank");
  };

  return (
    <Container>
      <DiscordWrapper>
        <div>
          <img src={theme.images.discordBlockText} className='discord-text' alt='discord-text' />
          <DiscordBlock bgimage={theme.images.discordBlock}>
            <DiscordIcon onClick={() => goToLink('https://discord.gg/meta3oys')} />
          </DiscordBlock>
        </div>
      </DiscordWrapper>
      <MinersWrapper bgimage={theme.images.minersBg}>
        <img src={theme.images.miners} alt='miners' />
      </MinersWrapper>
    </Container>
  )
}
