import React from 'react'
import { useTheme } from 'styled-components'
import { DiscordIcon } from '../Shared/SvgIcons'

import {
    DiscordButton
} from './styles'

export const DiscordBtn = () => {
    const theme = useTheme()

    return (
        <>
            <DiscordButton>
                <a href="https://discord.gg/meta3oys" target="_blank">
                    <div>
                        <DiscordIcon />
                    </div>
                    <img className='discord-text' src={theme.images.discordBlockText} />
                </a>
            </DiscordButton>
        </>
    )
}