import React from "react";
import { useTheme } from "styled-components";
import { GradientCard } from "../Shared";
import { QuestionAccordion } from "./QuestionAccordion";

import {
  FAQContainer,
  InnerContainer,
  TitleWrapper,
  Title,
  QuestionContainer,
} from "./styles";

export const FAQ = (props) => {
  const theme = useTheme();

  const questions = [
    {
      id: 1,
      title: "When will the Meta3oys launch take place?",
      description:
        "The official Meta3oys launch date will be announced soon in our Discord!",
    },
    {
      id: 2,
      title: "Why Meta3oys?",
      description:
        "Because the team is extremely solid. Each mind involved in the project is an elite in his own craft. The elegance & precise design he created has built some extremely advanced NFTs that sit among the most detailed avatars in the sphere. This combination of elite-level skills & minds makes Meta3oys one of the few long-term NFT projects that has a true vision.",
    },
    {
      id: 3,
      title: "Will there be a pre-sale?",
      description:
        "Yes. There will be a pre-sale announced soon for the WL members.",
    },
    {
      id: 4,
      title: "How can I view my NFTs?",
      description: "Once your Meta3oys avatars are minted and revealed, you can view them in your OpenSea account.",
    },
    {
      id: 5,
      title: "What blockchain is Meta3oys hosted on?",
      description:
        "The Meta3oys avatars live on the ETH blockchain.",
    },
    {
      id: 6,
      title: "How to buy a Meta3oy?",
      description: "Once the Meta3oy public sale goes live, you’ll be able to buy your Meta3oy on our website. At the moment you mint, a randomly selected NFT will be available to your Metamask account. If you want to see or sell your NFT, just go to your OpenSea account.",
    },
    {
      id: 7,
      title: "What will be the price of our NFT?",
      description: "To be announced.",
    },
    {
      id: 8,
      title: "How safe is it to buy a Meta3oy?",
      description: "100% safe. We use a smart-contract and minting system that has been audited and secured by a trusted team of experts in the sphere.",
    },
    {
      id: 9,
      title: "How many Meta3oys avatars do exist?",
      description: "Only 5555 NFTs will be released.",
    }
  ];

  return (
    <FAQContainer id="faq">
      <InnerContainer bgimage={theme.images.faqBg}>
        <TitleWrapper>
          <Title>
            <GradientCard />
            <h1>FAQ</h1>
          </Title>
        </TitleWrapper>

        {questions.map((question) => (
          <QuestionContainer key={question.id} data-aos="fade-up">
            <GradientCard>
              <QuestionAccordion question={question} />
            </GradientCard>
          </QuestionContainer>
        ))}
      </InnerContainer>
    </FAQContainer>
  );
};
