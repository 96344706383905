import styled, { css } from 'styled-components'

export const ProjectContainer = styled.div`
  padding-top: 90px;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Title = styled.div`
  position: relative;
  width: 50%;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 420px){
    width: 80%!important;
  }

  > div {
    position: absolute;
    width: 97px;
    height: 36px;
    left: -34px;
    top: -7px;
  }
  > h1 {
    position: inherit;
    font-size: 42px;
    margin: 0;
    text-align: center;
  }
  > p {
    text-align: center;
    > span {
      color: ${props => props.theme.colors.primary};
    }
  }
  @media (min-width: 768px) {
    > h1 {
      font-size: 63px;
    }

    > div {
      width: 136px;
      height: 53px;
      left: 60px;
      top: -16px;
    }
    > p {
      font-size: 24px;
    }
  }
  @media (min-width: 1440px) {
    > h1 {
      font-size: 85px;
    }
    > div {
      width: 270px;
      height: 70px;
      top: -20px
    }
    > p {
      font-size: 24px;
    }
  }
`

export const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  ${({ bgimage }) => bgimage && css`
    background-repeat: no-repeat, repeat;
    background-size: contain;
    object-fit: cover;
    background-position: center;
  `}

  > div {
    
    > div {
      margin-bottom: 30px;
      padding: 20px;
      height: fit-content;
      > p {
        > span {
          color: ${props => props.theme.colors.primary};
        }
      }
      > h2 {
        > span {
          color: ${props => props.theme.colors.primary};
        }
      }
      
    }
    
    > h2 {
      font-size: 32px;
      margin: 10 0;
      text-align: center;
    }

    > p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 300;
      span {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  @media (min-width: 768px) {
    width: 90%;
    padding-top: 80px;
    height: 1600px;

    > div {
      width: 60%;
      max-width: 450px;
      margin-left: 60px;

      > p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @media (min-width: 1024px) {
    height: 1600px;
    > div {
      margin-left: 200px;
      max-width: 500px;
    }
  }

  @media (min-width: 1440px) {
    height: 1600px;
    padding-top: 250px;
    margin-top: -150px;
    > div {
      margin-left: 300px;
      max-width: 730px;

      > p {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`

export const ContentWrapper = (props) => {
  const style = {}
  style.backgroundImage = `url(${props.bgimage})`

  return (
    <ContentWrapperStyled {...props} style={style}>
      {props.children}
    </ContentWrapperStyled>
  )
}

