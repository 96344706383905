import React from "react";
import { GradientCard } from "../Shared";
import { useTheme } from "styled-components";
import {
  ProjectContainer,
  Title,
  TitleWrapper,
  ContentWrapper,
} from "./styles";
import {
  BoxWrapper,
} from '../RoadMap/styles'
export const Project = () => {
  const theme = useTheme();

  return (
    <ProjectContainer>
      <TitleWrapper>
        <Title>
          <GradientCard />
          <h1>THE PROJECT</h1>
          <p>
            Meta3oys is a collection of 5555 highly detailed NFTs living on the ETH blockchain. Each collectible gives you access to a private club of <span><b>world-class entrepreneurs, business & investment knowledge,</b></span> and a long-term vision: <br /> <b><u>Becoming the first NFT in history that builds companies with the community.</u></b>
          </p>
        </Title>
      </TitleWrapper>
      <ContentWrapper bgimage={theme.images.projectBg}>
        <div data-aos="fade-up">
          <GradientCard>

            <h2>On a mission to end the rat race</h2>
            <p>
              In the year 4045, the entrepreneur species was almost extinct.
            </p>
            <p>
              The only thing that drives human beings is the rat race.
            </p>
            <p>
              The Meta3oys are a private club of elite A-players from the Metaverse and are the last ever species of entrepreneurs to exist.
            </p>
            <p>
              Driven by the hunger to spread their knowledge and to save the human species from their fate…
            </p>
            <p>They were put on a mission.</p>
            <p>Contact the humans from the year 2022 to change their destiny by getting the most hungry & determined ones involved in the club…</p>
          </GradientCard>
        </div>
        <div data-aos="fade-up">
          <GradientCard>
            <p>Only a few <span><b>A-players</b></span> will enter the private Meta3oys club…</p>
            <p><b><u>And access a network filled with elite entrepreneurs sharing their knowledge & building companies with the community.</u></b></p>
          </GradientCard>
        </div>
        <div data-aos="fade-up">
          <GradientCard>
            <h2>The more you learn, the more you earn</h2>
            <p>The goal of the Meta3oy private club is to make knowledge available to all.</p>
            <p>From Organizing <span><b>masterminds</b></span> with our <span><b>network</b></span> of <span><b>world-class entrepreneurs…</b></span></p>
            <p>Providing you with the <b>most valuable content & courses available in the universe…</b></p>
            <p>To even <b>building companies with the community.</b></p>
            <p><b><u>No matter where you are in the world, YOU deserve to become a CEO.</u></b></p>
            <p>The Meta3oys has one. Single. Priority.</p>
            <p>Taking everyone in the same direction:</p>
            <h2><span><b><u>SUCCESS.</u></b></span></h2>
          </GradientCard>
        </div>
      </ContentWrapper>
    </ProjectContainer>
  );
};
