import React from "react";
import { useTheme } from "styled-components";
import {
  TwitterIcon,
  DiscordIcon,
  OtherIcon,
  InstagramIcon,
  YoutubeIcon,
} from "../Shared/SvgIcons";

import {
  FooterContainer,
  LinksContainer,
  SocialLinksWrapper,
  SocialLink,
  LogoWrapper,
  MenuLinksWrapper,
  MenuLink,
  Divider,
  CopyWritter,
} from "./styles";

export const Footer = () => {
  const theme = useTheme();
  const menuItems = [
    { id: 1, name: "mint", value: "mint" },
    { id: 2, name: "roadmap", value: "roadmap" },
    { id: 3, name: "lightpaper", value: "lightpaper" },
    //{ id: 4, name: "whitelist", value: "whitelist" },
    { id: 5, name: "team", value: "team" },
    { id: 6, name: "faq", value: "faq" },
  ];

  const handleClickMenu = (index) => {
    let topPos = 0;
    if (index !== "whitelist" && index !== "lightpaper" && index !== "mint") {
      topPos = document.getElementById(index).offsetTop;
      window.scroll({
        top: topPos,
        left: 0,
        behavior: "smooth",
      });
    } else if (index === "whitelist") {
      window.open("http://wl.meta3oys.com", "_blank");
    } else if (index === "lightpaper") {
      window.open("https://meta3oys.com/Meta3oys_Lightpaper.pdf", "_blank");
    } else {
      window.open("https://mint.meta3oys.com", "_blank");
    }
  };

  return (
    <FooterContainer>
      <LinksContainer>
        <SocialLinksWrapper>
          <SocialLink href="https://instagram.com/meta3oys" target="_blank">
            <InstagramIcon />
          </SocialLink>
          <SocialLink href="https://twitter.com/meta3oys" target="_blank">
            <TwitterIcon />
          </SocialLink>
          <SocialLink href="https://www.youtube.com/channel/UCOg7lA6ADoPuxlzRFdtdWBA" target="_blank">
            <YoutubeIcon />
          </SocialLink>
          <SocialLink href="https://discord.gg/meta3oys" target="_blank">
            <DiscordIcon />
          </SocialLink>
          <SocialLink
            href="https://opensea.io/collection/meta3oys"
            target="_blank"
          >
            <OtherIcon />
          </SocialLink>
        </SocialLinksWrapper>

        <LogoWrapper>
          <img src={theme.logos.bigLogo} alt="" />
        </LogoWrapper>

        <MenuLinksWrapper>
          {menuItems.map((menu) => (
            <MenuLink key={menu.id} onClick={() => handleClickMenu(menu.value)}>
              {menu.name}
            </MenuLink>
          ))}
        </MenuLinksWrapper>
      </LinksContainer>
      <Divider />
      <CopyWritter>© 2022 META BOYS | All Rights Reserved</CopyWritter>
    </FooterContainer>
  );
};
