import React from "react";
import { GradientCard } from "../Shared/GradientCard"
import { useTheme } from "styled-components"
import {
  TwitterIcon,
  DiscordIcon,
  OtherIcon,
  InstagramIcon,
  EmailIcon,
  LinkedinIcon
} from "../Shared/SvgIcons";

import {
  TeamContainer,
  InnerContainer,
  TitleWrapper,
  Title,
  TeamSloganWrapper,
  TeamMemberContainer,
  TeamMemersList,
  Ellipse,
} from "./styles";

import {
  SocailWrapper,
  SocialLink,
} from "../Header/styles"

export const Team = () => {
  const theme = useTheme()
  const teamUsers = [
    {
      id: 1,
      name: "Alexei",
      position: "The 3D Artist",
      photo: "/images/alexei.jpg",
      description: 'This guy is a master of the most advanced & sophisticated 3D design techniques of this era.',
      instagram: "https://www.instagram.com/alexei_shelkov/",
    },
    {
      id: 2,
      name: "David",
      position: "The Blockchain Artist",
      photo: "/images/david.jpg",
      description: 'The legend says this guy came from the future to the year 2008 to show Satoshi how the blockchain works.',      
      email: "mailto: davidshal1980@yahoo.com",
      discord: "https://discordapp.com/users/davidshal#1980"
    },
    {
      id: 3,
      name: "Tarz",
      position: "The Studio",
      photo: "/images/tarz.jpg",
      description: "These are the ones who conceptualized the origins of the Meta3oys. A technical approach to 3D like no other.",
      instagram: "https://www.instagram.com/tartz__/"
    },    
    {
      id: 4,
      name: "Yamane",
      position: "The Web 3.0 Developer",
      photo: "/images/yamane.jpg",
      description: "This guy started coding before he was even born. He’s a true expert in Web 3.0.",
      email: "mailto:hanehiyamane@yahoo.com"
    },    
    {
      id: 5,
      name: "Mike",
      position: "The Marketing King",
      photo: "/images/mike.jpg",
      description: "With more than 10 years of experience in the digital marketing sphere, this guy knows how to make every project successful.",
      //linkedin: "https://www.linkedin.com/in/mike-hartman-bishop/"
      instagram: "https://www.instagram.com/rinshin_mikael/"
    }, 
    {
      id: 6,
      name: "Johnny & Waseem",
      position: "The Fashion Addicts",
      photo: "/images/johnny.jpg",
      description: "They are responsible for the elegance of the Meta3oys characters.",
      twitter: "https://twitter.com/WA5EEMSH4"
    },
    {
      id: 7,
      name: "Nazeer",
      position: "The Investor",
      photo: "/images/sergey.jpg",
      description: "He’s an expert at building projects & brands, making them impactful and long-term. He’s been in the business for over 20 years and has many successful international brands.",
      instagram: "https://www.instagram.com/nazeer_dxb/"
    },  
    {
      id: 8,
      name: "You",
      position: "The Foundation",
      photo: "/images/you.jpg",
      description: 'Because the community is everything. And without you, none of this would be possible. We believe that together, we can go way further than on our own. Let’s get to success together!',
      discord: "https://discord.gg/meta3oys"
    },

  ];

  return (
    <TeamContainer id="team" bgimage={theme.images.teamBg}>
      <InnerContainer>
        <TitleWrapper>
          <Title>
            <GradientCard />
            <h1>The Humans</h1>
          </Title>
        </TitleWrapper>

        <TeamSloganWrapper>
          <p>
            <span>
              <b>
                Your network is your net worth.
              </b>
            </span>
          </p>
          <p>
            Since we’re together, you must have a great net worth now.
          </p>
          <p>Our team generated over 9 figures in revenue in different fields.</p>
          <p>We’re a team that’s been around in the technological & business sphere for more than 20 years & in the blockchain industry for over 7 years.</p>
        </TeamSloganWrapper>

        <TeamMemersList>
          {teamUsers.map((user, index) => (
            <TeamMemberContainer key={user.id} data-aos="flip-left">
              <Ellipse index={(index + 1) % 6} />
              <img src={user.photo} alt="" />
              <div className="info-container">
                <span className="name">{user.name}</span>
                <span className="position">{user.position}</span>
                {user.description && (<span className="description">{user.description}</span>)}
                <SocailWrapper>
                  {user.instagram && (<SocialLink href={user.instagram} target="_blank"><InstagramIcon /></SocialLink>)}
                  {user.email && (<SocialLink href={user.email}><EmailIcon /></SocialLink>)}
                  {user.linkedin && (<SocialLink href={user.linkedin} target="_blank"><LinkedinIcon /></SocialLink>)}
                  {user.twitter && (<SocialLink href={user.twitter} target="_blank"><TwitterIcon /></SocialLink>)}
                  {user.discord && (<SocialLink href={user.discord} target="_blank"><DiscordIcon /></SocialLink>)}
                </SocailWrapper>
              </div>
            </TeamMemberContainer>
          ))}
        </TeamMemersList>
      </InnerContainer>
    </TeamContainer>
  );
};
