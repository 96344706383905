import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;


  @media (min-width: 768px) {

  }
`

export const DiscordWrapper = styled.div`
  position: absolute;
  left: 15px;
  > div {
    position: relative;
    padding: 20px;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .discord-text {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      animation: rotate 30s linear infinite;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  @media (min-width: 576px) {
    left: 40px;
    > div {
      width: 150px;
      height: 150px;
      padding: 20px;
    }
  }

  @media (min-width: 768px) {
    left: 120px;
    > div {
      width: 150px;
      height: 150px;
      padding: 20px;
    }
  }

  @media (min-width: 993px) {
    left: 150px;
    > div {
      width: 200px;
      height: 200px;
      padding: 30px;
    }
  }

  @media (min-width: 1200px) {
    left: 220px;
    > div {
      width: 250px;
      height: 250px;
      padding: 40px;
    }
  }

  @media (min-width: 1500px) {
    left: 300px;
    > div {
      width: 300px;
      height: 300px;
      padding: 50px;
    }
  }
`

export const MinersWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  max-width: 1400px;
  ${({ bgimage }) => bgimage && css`
    background-repeat: no-repeat, repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  `}
  img {
    width: 100%;
  }
`

export const MinersWrapper = (props) => {
  const style = {}
  style.backgroundImage = `url(${props.bgimage})`

  return (
    <MinersWrapperStyled {...props} style={style}>
      {props.children}
    </MinersWrapperStyled>
  )
}

export const DiscordBlockStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 23px;
  ${({ bgimage }) => bgimage && css`
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: cover;
    background-position: center;
  `}

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:hover {
      path {
        transition: all 0.2s linear;
        fill: ${props => props.theme.colors.primary};
      }
    }
  }

  @media (min-width: 576px) {
    padding: 28px;
  }

  @media (min-width: 768px) {
    padding: 30px;
  }

  @media (min-width: 993px) {
    padding: 40px;
  }

  @media (min-width: 1200px) {
    padding: 50px;
  }

  @media (min-width: 1500px) {
    padding: 60px;
  }
`

export const DiscordBlock = (props) => {
  const style = {}
  style.backgroundImage = `url(${props.bgimage})`

  return (
    <DiscordBlockStyled {...props} style={style}>
      {props.children}
    </DiscordBlockStyled>
  )
}


