import React from 'react'
import { useTheme } from 'styled-components'
import { GradientCard } from '../Shared'
import {
  RoadMapContainer,
  BackgroundListWrapper,
  TopImage,
  MiddleImage,
  BottomImage,
  ContentWrapper,
  BoxWrapper,
  LeftNumberWrapper,
  PointBox,
  RightNumberWrapper,
  TitleWrapper,
  Title,
  BgWrapper
} from './styles'
import { useWindowSize } from '../../hooks/useWindowSize'
import { CircleProgressBar } from './CircleProgressBar'

export const RoadMap = () => {
  const theme = useTheme()
  const { width } = useWindowSize()

  return (
    <>
      <RoadMapContainer id='roadmap' bgimage={theme.images.gradientBg}>
        <TitleWrapper>
          <Title>
            <GradientCard />
            <h1>THE ROADMAP</h1>
          </Title>
        </TitleWrapper>
        <BgWrapper>
          <ContentWrapper>
            <BoxWrapper isLeft>
              <LeftNumberWrapper data-aos="fade-zoom-in">
                {width > 576 && <PointBox id='d1' className='d1'><div /></PointBox>}
                <span>1</span>
              </LeftNumberWrapper>
              <div className='gradient-box' data-aos="fade-up">
                <GradientCard>
                  <h1>Welcome to Meta3oys</h1>
                  <p>The 5555 unique & elegant Meta3oys make their first appearance in the physical world.</p>
                  <p>We’re starting to reward the A-players who support and believe in the project.</p>
                  <p>We’ll be giving out <span><b>free merch</b></span> that Meta3oys brought straight from the year 4045 for you, along with uniquely crafted <span><b>digital gifts.</b></span></p>
                </GradientCard>
              </div>
            </BoxWrapper>
            <BoxWrapper>
              <div className='gradient-box' data-aos="fade-up">
                <GradientCard>
                  <div className='gradient-header'>
                    <CircleProgressBar percent={20} />
                    <h1>The Charity Donation</h1>
                  </div>
                  <p>We will donate <span><b>$50,000</b></span> to a charity cause chosen by you: the community.</p>
                  <p>The internet world is often seen as a profit-oriented sphere.</p>
                  <p><u><b>We believe in giving back to the world, and want to have a true impact on society.</b></u></p>
                  <p>That’s why we strive to help people in need.</p>
                  <p>We’re committed to changing this planet, and you’re doing it with us.</p>
                </GradientCard>
              </div>
              <RightNumberWrapper data-aos="fade-zoom-in">
                <span>2</span>
                {width > 576 && <PointBox id='d2' className='d2'><div className='d2-1' /></PointBox>}
              </RightNumberWrapper>
            </BoxWrapper>
            <BoxWrapper isLeft>
              <LeftNumberWrapper data-aos="fade-zoom-in">
                {width > 576 && <PointBox id='d3' className='d3'><div className='d3-1' /></PointBox>}
                <span>3</span>
              </LeftNumberWrapper>
              <div className='gradient-box' data-aos="fade-up">
                <GradientCard>
                  <div className='gradient-header'>
                    <h1>Knowledge Is Power</h1>
                    <CircleProgressBar percent={40} />
                  </div>
                  <p>Meta3oys is a network created by world-class <span><b>entrepreneurs</b></span> & <span><b>investors.</b></span></p>
                  <p>We truly believe that knowledge & teamspirit is power.</p>
                  <p>More than anything else, Meta3oy is a <b><u>movement.</u></b></p>
                  <p>A movement that connects <b>business, crypto, NFT & investment</b> lovers and provides them with the most valuable & knowledgeable minds in these fields.</p>
                  <p>Sharing the knowledge of our network with our supporters will put them ahead of the game.</p>
                  <p>We will give you access to exclusive online courses, zoom calls, exclusive coaching sessions, and more, so we can become the most powerful community of entrepreneurs.</p>
                </GradientCard>
              </div>
            </BoxWrapper>
            <BoxWrapper>
              <div className='gradient-box' data-aos="fade-up">
                <GradientCard>
                  <div className='gradient-header'>
                    <CircleProgressBar percent={60} />
                    <h1>We don’t build NFTs. We build success.</h1>
                  </div>
                  <p>If you’re in the Meta3oy community, that means you’re an A-player.</p>
                  <p>We believe that everybody deserves to become a CEO.</p>
                  <p>That’s why we want to <b>fund your projects</b> and make them become the successful business they deserve to be.</p>
                  <p>We will invest <span><b>$400,000</b></span> in YOUR business projects and make them come true.</p>
                  <p>We don’t build NFTs. We build success.</p>
                </GradientCard>
              </div>
              <RightNumberWrapper data-aos="fade-zoom-in">
                <span>4</span>
                {width > 576 && <PointBox id='d4' className='d4'><div className='d4-1' /></PointBox>}
              </RightNumberWrapper>
            </BoxWrapper>
            <BoxWrapper isLeft>
              <LeftNumberWrapper data-aos="fade-zoom-in">
                {width > 576 && <PointBox id='d5' className='d5'><div className='d5-1' /></PointBox>}
                <span>5</span>
              </LeftNumberWrapper>
              <div className='gradient-box' data-aos="fade-up">
                <GradientCard>
                  <div className='gradient-header'>
                    <h1>The MetaMeetings</h1>
                    <CircleProgressBar percent={80} />
                  </div>
                  <p>We will organize meetings for <span><b>entrepreneurs, crypto & NFTs</b></span> lovers who are willing to meet like-minded people who HOLD Meta3oy avatar.</p>
                  <p>This is going to be a private VIP area where the community can <b>exchange ideas, business knowledge</b>, and impact the world together.</p>
                  <p>We’ll also launch a <b>Metaverse shop</b> where you’ll be able to invest in the most elegant & sophisticated outfits for your avatar.</p>
                  <p>At this stage, your Meta3oy avatar will be fully customizable to your own image.</p>
                  <p>Every 3 months, your Meta3oy will be rewarded with exclusive & unique Metaverse clothing that you’ll be able to sell & <u><b>automatically generate passive income</b></u>!</p>
                </GradientCard>
              </div>
            </BoxWrapper>
            <BoxWrapper>
              <div className='gradient-box' data-aos="fade-up">
                <GradientCard>
                  <div className='gradient-header'>
                    <CircleProgressBar percent={100} />
                    <h1>The MetaEvents</h1>
                  </div>
                  <p>We’re in this for the <b>long-run.</b></p>
                  <p>Long-term holders will get access to private masterminds both in the physical and virtual worlds.</p>
                  <p>These events will be held in the most awesome cities in the world:</p>
                  <p><b>LOS ANGELES, MIAMI, NEW YORK, PARIS, and DUBAI...</b></p>
                  <p>Also, once you hold a Meta3oy, you’ll get access to <span><b>exclusive private parties</b></span> where some of <b>the most powerful & knowledgeable minds on earth</b> will be attending.</p>
                </GradientCard>
              </div>
              <RightNumberWrapper data-aos="fade-zoom-in">
                <span>6</span>
                {width > 576 && <PointBox id='d6' className='d6'><div /></PointBox>}
              </RightNumberWrapper>
            </BoxWrapper>
          </ContentWrapper>
          <BackgroundListWrapper>
            <div>
              <TopImage src={width > 1440 ? theme.images.roadMapTop : theme.images.roadMapTop1} alt='top-road-map' />
              <MiddleImage src={width > 1440 ? theme.images.roadMapMiddle : theme.images.roadMapMiddle1} alt='middle-road-map' />
              <BottomImage src={width > 1440 ? theme.images.roadMapBottom : theme.images.roadMapBottom1} alt='bottom-road-map' />
            </div>
          </BackgroundListWrapper>
        </BgWrapper>
      </RoadMapContainer>
    </>

  )
}
