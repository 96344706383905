import styled, { css } from 'styled-components'

export const MintContainerStyled = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 150px;
  height: 570px;
  @media (max-width: 420px){
    height: 470px!important;
  }
  ${({ bgimage }) => bgimage && css`
    background-repeat: no-repeat, repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    @media (max-width: 420px){
      background-position: left!important;
    }
  `}
`

export const MintContainer = (props) => {
  const style = {}
  style.backgroundImage = `linear-gradient(179.92deg, #000000 0.07%, rgba(0, 0, 0, 0) 102.47%), url(${props.bgimage})`

  return (
    <MintContainerStyled {...props} style={style}>
      {props.children}
    </MintContainerStyled>
  )
}

export const MintContent = styled.div`
  width: 100%;
  padding: 15px;

  @media (min-width: 768px) {
    width: 80%;
  }
`

export const ContentBox = styled.div`
  width: 100%;
  
  h1 {
    font-size: 24px;
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 26px;
  }

  h3 {
    font-style: italic;
    font-weight: 500;
  }

  .mint-box {
    display: flex;
    flex-direction: column;
  }
  .countdown {
    display: flex;
    color: #fff;
    font-size: 2rem;
  }
  .countdown span {
    padding: 0.5rem;
  }
  .countdown-box {
    padding: 0.5rem;
  }
  > div {
    > a {
      > div {
        margin-top: 0.5rem;
        height: 105px;
        width: 380px;
        > div {
          width: 280px;
          height: 88px;
          > div {
            font-size: 3rem;
            width: 273px;
            height: 80px;
          }
        }
        
      }
    }
  }
  @media screen and (min-width: 650px) {
    .countdown-text{
      font-size: 2rem;
    }
    .countdown {
      flex-direction: row;
    }
  }
  @media screen and (max-width: 649px) {
    .countdown {
      text-align: center;
      flex-direction: column;
    }
    > div {
      > a {
        > div {
          margin-left: auto!important;
          margin-right: auto!important;
        }
      }
    }
  }

  @media (max-width: 420px){
    h1,h3 {
      text-align: center;
    }
  }

  @media (min-width: 768px) {
    width: 70%;
    h3 {
      font-size: 24px;
    }
    h1 {
      font-size: 32px;
      line-height: 45px;
      margin-bottom: 42px; 
    }
  }

  @media (min-width: 992px) {
    h1 {
      font-size: 42px;
      line-height: 55px;
      margin-bottom: 35px; 
    }
  }

  @media (min-width: 1440px) {
    width: 60%;
    h1 {
      font-size: 55px;
      line-height: 65px;
      margin-bottom: 52px;
    }
  }
`