import styled, { css } from 'styled-components'

export const DiscordButton = styled.div`
    > a {
        position: fixed;
        right: 24px;
        bottom: 24px;
        z-index: 1000000;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100px;
        height: 100px;
        padding: 15px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 100%;
        background-color: #FF007A;
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        cursor: pointer;
        > div {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            height: 100%;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 100%;
            background-image: -webkit-gradient(linear,left bottom,left top,from(#be51ff),to(#42255b));
            background-image: linear-gradient(360deg,#be51ff,#42255b);
        }
        > img {
            position: absolute;
            width: 90%;
            height: 90%;
        }
        .discord-text {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            padding: 5px;
            animation: rotate 30s linear infinite;
        }
    }
    
`